import { ClientStatus } from '__generated__/types';
import { useConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useFilter } from 'utils/filterContext';

const HostFilters = () => {
  const { hostFilter, setHostFilter, authFilter } = useFilter();
  const filters = {
    Phones: 'Phone',
    PC_01: 'PC01',
    PC_02: 'PC02',
    PC_03: 'PC03',
    PC_04: 'PC04',
    PC_05: 'PC05',
    PC_06: 'PC06',
    PC_07: 'PC07',
    PC_08: 'PC08',
  };

  const { data: accs } = useConnectionSessionWAList({ variables: { filter: {} } });
  const [filteredAccounts, setFilteredAccounts] = useState<
    (ClientStatus | null)[] | null | undefined
  >();

  useEffect(() => {
    const filteredAccounts = accs?.connectionSessionWAList
      ?.filter((acc) =>
        authFilter.toLowerCase()?.includes(acc?.waClient?.status?.toString()?.toLowerCase() || '')
      )
      .map((acc) => ({ ...acc, _id: acc?.waClient?._id || '' }));
    setFilteredAccounts(filteredAccounts || null);
  }, [accs, authFilter]);

  return (
    <motion.div
      key="host-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {Object.entries(filters).map(([key, value], index) => (
        <button
          key={key}
          onClick={() => {
            hostFilter === value ? setHostFilter('') : setHostFilter(value);
          }}
          className={`group flex select-none items-center justify-center rounded-lg text-sm font-medium duration-150 dark:text-white ${hostFilter === value ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-2`}
        >
          <p
            className={`text-sm font-semibold ${hostFilter === value ? 'text-white' : 'dark:text-white'}`}
          >
            {key}
          </p>
          {filteredAccounts &&
            filteredAccounts?.filter((acc) => acc?.waClient?.host?.includes(value)).length > 0 && (
              <motion.p
                key={index}
                animate={{ scale: [0.5, 1], x: [-10, 0] }}
                transition={{
                  duration: 0.4,
                  x: { delay: 0.3 + index * 0.1 },
                  scale: { delay: 0.1 + index * 0.1 },
                }}
                className="flex h-5 w-10 shrink-0 items-center justify-center rounded-md bg-activeLinkLight text-sm text-gray-600 duration-150 group-hover:bg-surfaceLight dark:bg-activeLinkDark dark:text-white dark:group-hover:bg-surfaceDark"
              >
                {filteredAccounts?.filter((acc) => acc?.waClient?.host?.includes(value)).length}
              </motion.p>
            )}
        </button>
      ))}
    </motion.div>
  );
};

export default HostFilters;
