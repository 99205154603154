import { useUser } from '@clerk/clerk-react';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext({} as { userRole: string; userOrg: string; userName: string });

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { user, isLoaded } = useUser();
  const [userRole, setUserRole] = useState('');
  const [userOrg, setUserOrg] = useState('');
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && user?.organizationMemberships[0]?.role) {
      setUserRole(user.organizationMemberships[0].role.slice(4));
      setUserOrg(user.organizationMemberships[0].organization.name);
      setUserName(user.fullName?.toString() || 'user');
      if (userOrg === 'AC_OPGT') {
        navigate('/best-offer');
      }
    } else if (isLoaded && !user?.organizationMemberships[0]?.role) {
      navigate('/access-denied');
    }
  }, [user, isLoaded, navigate, userOrg]);

  return (
    <UserContext.Provider value={{ userRole, userOrg, userName }}>{children}</UserContext.Provider>
  );
};

export const useUserRole = () => {
  return useContext(UserContext);
};
