import { useFullscreen } from 'app/providers/fullscreenProvider/fullscreenProvider';
import { useUserRole } from 'app/providers/userProvider/userProvider';
import { useState } from 'react';
import SideMenu from 'shared/components/sideMenu';
import UserSearchWidget from 'widgets/userSearchWidget/userSearchWidget';
import Header from 'widgets/header';
import UserSearch from 'features/userSearch/userSearch';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { userRole, userOrg } = useUserRole();
  const { fullscreen } = useFullscreen();
  const isOpgt = userOrg === 'AC_OPGT';

  const [isUserSearchOpen, setIsUserSearchOpen] = useState(true);

  const handleUserSearchClick = () => {
    setIsUserSearchOpen(!isUserSearchOpen);
  };

  return (
    <>
      <Header />
      <main className="relative flex h-[calc(100vh-110px)] w-full gap-x-2 px-[6%] lg:px-[1%] xl:gap-x-6">
        {userRole && !fullscreen && !isOpgt && (
          <div className="pageBlock min-w-[250px]">
            <SideMenu />
          </div>
        )}
        <div className="flex w-full flex-row gap-x-2 xl:gap-x-6">{children}</div>
      </main>
      {isUserSearchOpen ? (
        <UserSearchWidget isOpen={isUserSearchOpen} handleClick={handleUserSearchClick} />
      ) : (
        <UserSearch handleClick={handleUserSearchClick} />
      )}
    </>
  );
}
