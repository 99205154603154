// 🛑 NOTICE: __generated__ folders should be added to .gitignore
// 🛑 In this repo I keep generated files only for demo purposes!
import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type whatsappListVariables = Types.Exact<{ [key: string]: never; }>;


export type whatsappList = { __typename: 'Query', whatsappList: Array<{ __typename: 'Whatsapp', phone?: number | null, status?: Types.EnumWhatsappStatus | null, email?: string | null, name?: string | null, profilePicUrl?: string | null, host?: string | null, unreadCount?: number | null, sessionCount?: number | null, openReadChats?: Array<string | null> | null, autoSeenChats?: Array<string | null> | null, allCount?: number | null, repliedCount?: number | null, _id: any }> };


export const whatsappListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"whatsappList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"whatsappList"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"phone"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"profilePicUrl"}},{"kind":"Field","name":{"kind":"Name","value":"host"}},{"kind":"Field","name":{"kind":"Name","value":"unreadCount"}},{"kind":"Field","name":{"kind":"Name","value":"sessionCount"}},{"kind":"Field","name":{"kind":"Name","value":"openReadChats"}},{"kind":"Field","name":{"kind":"Name","value":"autoSeenChats"}},{"kind":"Field","name":{"kind":"Name","value":"allCount"}},{"kind":"Field","name":{"kind":"Name","value":"repliedCount"}},{"kind":"Field","name":{"kind":"Name","value":"_id"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __usewhatsappList__
 *
 * To run a query within a React component, call `usewhatsappList` and pass it any options that fit your needs.
 * When your component renders, `usewhatsappList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usewhatsappList({
 *   variables: {
 *   },
 * });
 */
export function usewhatsappList(baseOptions?: ApolloReactHooks.QueryHookOptions<whatsappList, whatsappListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<whatsappList, whatsappListVariables>(whatsappListDocument, options);
      }
export function usewhatsappListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<whatsappList, whatsappListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<whatsappList, whatsappListVariables>(whatsappListDocument, options);
        }
export type whatsappListHookResult = ReturnType<typeof usewhatsappList>;
export type whatsappListLazyQueryHookResult = ReturnType<typeof usewhatsappListLazyQuery>;
export type whatsappListQueryResult = Apollo.QueryResult<whatsappList, whatsappListVariables>;