import { SearchOutlined } from '@ant-design/icons';
import { Whatsapp } from '__generated__/types';
// import { useChatsWA } from 'features/waChats/__generated__/ChatsWAQuery';
import { motion } from 'framer-motion';
import IMask, { InputMask } from 'imask';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Cross } from 'shared/assets/icons/broadcast/icons';
import { formatPhoneNumber } from 'utils/formatPhoneNumber';
import { usewhatsappList } from './__generated__/waAccountsQuery';
import UserAvatar from 'shared/assets/images/user.png';
// import { DatabaseSearch } from './databaseSearch';

interface Props {
  handleClick: () => void;
}

interface User extends Whatsapp {
  _id: string;
  name: string;
  phone: number;
  inn: string;
}

const UserSearch = ({ handleClick }: Props) => {
  const { data: waAccounts } = usewhatsappList();

  const [, setUser] = useState<User | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [mask, setMask] = useState<InputMask | null>(null);
  const [matchedAccounts, setMatchedAccounts] = useState<Whatsapp[] | null>(null);
  const [searchType, setSearchType] = useState<'phone' | 'name' | 'inn' | null>(null);

  const maskOptions = useMemo(
    () => ({
      mask: '+{7}(000)000-00-00',
    }),
    []
  );

  // const dataBase = DatabaseSearch();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (searchType === 'name') {
      const nameRegex = /^[а-яА-Яa-zA-Z\s]*$/;
      if (nameRegex.test(e.target.value)) {
        setInputValue(e.target.value);
      } else {
        return;
      }
    } else if (searchType === 'inn') {
      const innRegex = /^\d*$/;
      if (innRegex.test(e.target.value)) {
        setInputValue(e.target.value);
      } else {
        return;
      }
    } else {
      setInputValue(e.target.value);
    }
  };

  const handleClearInput = (element: HTMLElement) => {
    if (element) {
      (element as HTMLInputElement).value = '';
    }
    setInputValue('');
  };

  const handleFindUser = async (value: string) => {
    const user = await waAccounts?.whatsappList?.find((acc) =>
      searchType === 'phone'
        ? acc?.phone?.toString() === mask?.unmaskedValue
        : searchType === 'name'
          ? acc?.name?.toLowerCase() === value.toLowerCase()
          : false
    );
    setUser(user as User);
  };

  useEffect(() => {
    if (searchType === 'phone' && inputRef.current) {
      const newMask = IMask(inputRef.current, maskOptions);
      setMask(newMask);
      return () => {
        if (newMask) {
          newMask.destroy();
        }
      };
    } else {
      if (mask) {
        mask.destroy();
        setMask(null);
      }
    }
  }, [searchType, maskOptions, mask]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = '';
      handleClearInput(inputRef.current);
    }
  }, [searchType]);

  useEffect(() => {
    if (mask && mask.unmaskedValue) {
      const foundAccounts = waAccounts?.whatsappList?.filter((acc) =>
        acc?.phone?.toString().includes(mask.unmaskedValue)
      );
      setMatchedAccounts(foundAccounts || null);
    } else {
      setMatchedAccounts(null);
    }
  }, [mask?.unmaskedValue, waAccounts?.whatsappList, mask]);

  useEffect(() => {
    if (mask && searchType === 'phone') {
      mask.updateValue();
    }
  }, [inputValue, mask, searchType]);

  return (
    <motion.div
      key={'userSearch'}
      animate={{ width: [0, 400] }}
      transition={{ duration: 0.3 }}
      className="scrollable absolute right-0 top-20 h-full rounded-l-2xl bg-surfaceDarker p-3 dark:bg-surfaceDark"
    >
      <div className="flex flex-row items-center justify-end">
        <button
          onClick={handleClick}
          className="hoverable rounded-xl bg-surfaceLight p-1 dark:bg-surfaceLighter"
        >
          <Cross className="size-8" />
        </button>
      </div>

      <div className="mt-3 flex flex-col items-start gap-y-1">
        <p className="select-none dark:text-white">Как будем искать?</p>
        <div className="flex w-full flex-row gap-x-1">
          <motion.button
            key={'phone'}
            animate={{
              opacity: [0, 1],
              y: [10, 0],
            }}
            transition={{ duration: 0.2, opacity: { delay: 0.2 }, y: { delay: 0.2 } }}
            onClick={() => setSearchType('phone')}
            className={`hoverable w-full rounded-l-xl p-2 ${
              searchType === 'phone' ? 'activeLink' : 'inactiveLink'
            }`}
          >
            <p className="select-none dark:text-white">По номеру</p>
          </motion.button>
          <motion.button
            key={'name'}
            animate={{
              opacity: [0, 1],
              y: [10, 0],
            }}
            transition={{ duration: 0.2, opacity: { delay: 0.3 }, y: { delay: 0.3 } }}
            onClick={() => setSearchType('name')}
            className={`hoverable w-full p-2 ${searchType === 'name' ? 'activeLink' : 'inactiveLink'}`}
          >
            <p className="select-none dark:text-white">По имени</p>
          </motion.button>
          <motion.button
            key={'inn'}
            animate={{
              opacity: [0, 1],
              y: [10, 0],
            }}
            transition={{ duration: 0.2, opacity: { delay: 0.4 }, y: { delay: 0.4 } }}
            onClick={() => setSearchType('inn')}
            className={`hoverable w-full rounded-r-xl p-2 ${
              searchType === 'inn' ? 'activeLink' : 'inactiveLink'
            }`}
          >
            <p className="select-none dark:text-white">По ИНН</p>
          </motion.button>
        </div>
      </div>

      {searchType && (
        <motion.div
          key={'userSearchInput'}
          animate={{
            opacity: [0, 1],
            y: [10, 0],
          }}
          transition={{ duration: 0.2 }}
          className="mt-3 flex flex-col items-start gap-y-1"
        >
          <p className="select-none text-sm text-gray-500 dark:text-gray-300">
            {searchType === 'phone' && 'Введите номер'}
            {searchType === 'name' && 'Введите имя (и/или) фамилию'}
            {searchType === 'inn' && 'Введите ИНН'}
          </p>
          <div className="flex w-full flex-row items-center gap-x-1">
            <input
              ref={inputRef}
              id={`userSearchInput${searchType}`}
              value={inputValue}
              autoFocus
              spellCheck={false}
              className="w-full rounded-l-xl bg-surfaceLight p-2 dark:bg-surfaceLighter dark:text-white"
              placeholder={
                searchType === 'phone'
                  ? 'Номер телефона'
                  : searchType === 'name'
                    ? 'Имя и/или фамилия'
                    : 'ИНН'
              }
              onChange={handleInputChange}
            />
            <button
              onClick={() => handleFindUser(inputValue)}
              className="hoverable rounded-r-xl bg-surfaceLight px-4 py-2 dark:bg-surfaceLighter"
            >
              <SearchOutlined className="text-xl dark:text-white" />
            </button>
          </div>
        </motion.div>
      )}
      {matchedAccounts ? (
        matchedAccounts.length > 0 ? (
          matchedAccounts.map((acc) => {
            return (
              <div
                key={acc._id}
                className="mt-4 flex w-full flex-col items-center justify-center gap-y-2 rounded-xl bg-surfaceLight p-2 dark:bg-surfaceLighter"
              >
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col">
                    <div className="flex flex-col items-start">
                      <p className="select-none font-semibold leading-tight dark:text-white">
                        Имя:
                      </p>
                      <p className="leading-tight text-gray-500 dark:text-gray-300">{acc.name}</p>
                    </div>
                    <div className="mt-2 flex flex-col items-start">
                      <p className="select-none font-semibold leading-tight dark:text-white">
                        Телефон:
                      </p>
                      <p className="leading-tight text-gray-500 dark:text-gray-300">
                        {formatPhoneNumber(acc.phone)}
                      </p>
                    </div>
                  </div>
                  {acc.profilePicUrl && (
                    <img
                      src={acc.profilePicUrl}
                      alt="profile"
                      className="w-1/3 rounded-xl"
                      onError={(e) => (e.currentTarget.src = UserAvatar)}
                    />
                  )}
                </div>
                <button className="hoverable rounded-full bg-surfaceDarker px-4 py-2 text-sm dark:bg-surfaceDark dark:text-white">
                  Дополнительные сведения
                </button>
              </div>
            );
          })
        ) : (
          <p className="mt-4 select-none dark:text-white">Не найдено ни одного пользователя</p>
        )
      ) : null}

      {waAccounts?.whatsappList?.map((acc) => {
        return <p key={Math.random()}>{acc?.phone}</p>;
      })}
    </motion.div>
  );
};

export default UserSearch;
