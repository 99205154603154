import { motion } from 'framer-motion';
import { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  className?: string;
}

export const WhatsApp = (props: IconProps) => (
  <svg {...props} width="24" height="24" viewBox="0 0 500 500">
    <path
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
      d="M19.89,452.503c-2.486,4.973,0,9.945,2.486,12.431c4.973,2.486,9.945,2.486,14.918,2.486l74.588-27.349 c37.294,24.863,82.047,37.294,126.8,37.294c131.773,0,238.683-106.91,238.683-238.683S370.456,0,238.683,0S0,106.91,0,238.683 c0,49.726,14.918,99.451,44.753,139.232L19.89,452.503z M24.863,236.197c0-119.342,96.965-213.82,213.82-213.82 s213.82,96.965,213.82,213.82s-96.965,213.82-213.82,213.82c-42.267,0-84.534-12.431-119.342-34.808 c-2.486-2.486-4.973-2.486-7.459-2.486c-2.486,0-2.486,0-4.973,0l-57.185,22.377l19.89-57.184c2.486-4.973,0-7.459-2.486-12.431 C39.781,328.189,24.863,283.436,24.863,236.197z"
    />
    <path
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
      d="M285.923,382.888c12.431,2.486,22.377,4.973,34.808,4.973c29.835,0,52.212-12.431,67.13-34.808 c7.459-14.918,9.945-27.349,7.459-39.781c-4.973-14.918-17.404-22.377-19.89-24.863l-37.294-19.89 c-4.973-2.486-9.945-4.973-14.918-4.973c-24.863,0-39.781,27.349-39.781,27.349s-2.486,2.486-7.459,2.486 c-2.486,0-2.486,0-2.486,0c-37.294-12.431-64.643-47.239-77.075-64.643l-2.486-2.486c-9.945-7.459-12.431-7.459-2.486-17.404 c19.89-17.404,14.918-37.294,9.945-44.753l-24.863-59.671c-2.486-4.973-12.431-12.431-22.377-12.431 c-12.431,0-27.349,7.459-44.753,24.863c-42.267,42.267-9.945,106.91-9.945,106.91C146.691,348.08,280.95,380.401,285.923,382.888z M126.8,134.259c14.918-17.404,24.863-17.404,27.349-17.404l27.349,59.671c0,2.486,2.486,7.459-4.973,14.918 c-24.863,22.377-17.404,37.294-4.973,52.212l2.486,2.486c12.431,17.404,44.753,59.671,89.506,72.102c0,0,4.973,0,9.945,0 c12.431,0,22.377-4.973,29.835-14.918c2.486-7.459,14.918-19.89,24.863-14.918l37.294,17.404c0,0,4.973,2.486,7.459,7.459 c2.486,7.459-2.486,14.918-4.973,19.89c-12.431,22.377-37.294,27.349-72.102,19.89c-4.973,0-126.8-32.322-171.554-141.718 C121.828,216.307,96.965,166.581,126.8,134.259z"
    />
  </svg>
);

export const Broadcast = (props: IconProps) => (
  <svg
    {...props}
    className="fill-[#4d5666] dark:fill-[#ebeef2]"
    height="32"
    width="32"
    viewBox="0 0 511.999 511.999"
  >
    <path d="M455.517,94.082H126.866c-31.145,0-56.483,25.338-56.483,56.483v81.624h-54.21C7.241,232.189,0,239.431,0,248.363 c0,8.932,7.241,16.173,16.173,16.173h54.21v43.425H43.336c-8.932,0-16.173,7.241-16.173,16.173 c0,8.932,7.241,16.173,16.173,16.173h27.046v21.127c0,31.145,25.338,56.483,56.483,56.483h328.651 c31.145,0,56.483-25.338,56.483-56.483V150.566C512,119.421,486.662,94.082,455.517,94.082z M126.866,126.429h328.651 c5.736,0,11.008,2.015,15.154,5.369L313.698,246.985c-13.251,9.724-31.762,9.724-45.013,0L111.712,131.798 C115.857,128.444,121.13,126.429,126.866,126.429z M479.654,361.434c0,13.309-10.827,24.137-24.137,24.137H126.866 c-13.309,0-24.137-10.827-24.137-24.137v-21.127h60.902c8.932,0,16.173-7.241,16.173-16.173s-7.241-16.173-16.173-16.173h-60.902 V165.327l146.82,107.736c12.26,8.998,26.949,13.494,41.642,13.494c14.689,0,29.384-4.498,41.642-13.494l146.82-107.736V361.434z" />
  </svg>
);

export const Pen = (props: IconProps) => (
  <svg
    {...props}
    width="64px"
    height="64px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M15.4998 5.49994L18.3282 8.32837M3 20.9997L3.04745 20.6675C3.21536 19.4922 3.29932 18.9045 3.49029 18.3558C3.65975 17.8689 3.89124 17.4059 4.17906 16.9783C4.50341 16.4963 4.92319 16.0765 5.76274 15.237L17.4107 3.58896C18.1918 2.80791 19.4581 2.80791 20.2392 3.58896C21.0202 4.37001 21.0202 5.63634 20.2392 6.41739L8.37744 18.2791C7.61579 19.0408 7.23497 19.4216 6.8012 19.7244C6.41618 19.9932 6.00093 20.2159 5.56398 20.3879C5.07171 20.5817 4.54375 20.6882 3.48793 20.9012L3 20.9997Z"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const Node = (props: IconProps) => (
  <svg {...props} width="800px" height="800px" viewBox="0 0 1024 1024">
    <path
      d="M541.279573 529.974613c-17.44896-10.07616-43.88864-11.175253-59.00288-2.450773L85.2992 756.722347c-15.121067 8.72448-13.216427 23.988907 4.232533 34.065066l388.5056 224.303787c17.455787 10.07616 43.895467 11.175253 59.009707 2.450773l396.977493-229.198506c15.11424-8.72448 13.216427-23.988907-4.23936-34.065067l-388.5056-224.303787z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />

    <path
      d="M75.093333 249.883307v521.632426l434.56512 251.890347V501.773653L75.093333 249.883307z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />

    <path
      d="M507.262293 495.363413l436.927147-247.207253v524.376747L507.262293 679.0144V495.363413z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />

    <path
      d="M541.279573 8.33536C523.830613-1.7408 497.390933-2.833067 482.276693 5.891413L85.2992 235.083093c-15.121067 8.731307-13.216427 23.995733 4.232533 34.071894l388.5056 224.303786c17.455787 10.07616 43.895467 11.168427 59.009707 2.443947l396.977493-229.19168c15.11424-8.731307 13.216427-23.995733-4.23936-34.071893L541.279573 8.33536zM117.848747 430.30528c0 17.005227 10.349227 36.78208 23.10144 44.148053l179.042986 103.36256c12.752213 7.365973 23.10144-0.464213 23.10144-17.462613 0-17.005227-10.349227-36.78208-23.10144-44.148053L140.950187 412.842667c-12.752213-7.365973-23.10144 0.464213-23.10144 17.462613z"
      fill="#9ba5b2"
    />

    <path
      d="M429.458773 579.413333c14.72512 8.4992 26.678613 29.20448 26.678614 46.20288 0 17.005227-11.953493 23.906987-26.678614 15.407787-14.718293-8.506027-26.678613-29.211307-26.678613-46.209707 0-17.005227 11.96032-23.906987 26.678613-15.40096z"
      fill="#0070b9"
    />

    <path
      d="M117.848747 562.56512c0 17.005227 10.349227 36.78208 23.10144 44.148053l179.042986 103.369387c12.752213 7.359147 23.10144-0.464213 23.10144-17.46944 0-16.9984-10.349227-36.78208-23.10144-44.141227L140.950187 545.102507c-12.752213-7.365973-23.10144 0.464213-23.10144 17.462613z"
      fill="#9ba5b2"
    />

    <path
      d="M429.458773 711.673173c14.72512 8.4992 26.678613 29.20448 26.678614 46.209707 0 16.9984-11.953493 23.90016-26.678614 15.40096-14.718293-8.4992-26.678613-29.20448-26.678613-46.209707 0-16.9984 11.96032-23.90016 26.678613-15.40096z"
      fill="#0070b9"
    />

    <path
      d="M117.848747 694.831787c0 16.9984 10.349227 36.78208 23.10144 44.141226l179.042986 103.369387c12.752213 7.359147 23.10144-0.464213 23.10144-17.46944 0-16.9984-10.349227-36.78208-23.10144-44.141227L140.950187 677.362347c-12.752213-7.359147-23.10144 0.464213-23.10144 17.46944z"
      fill="#9ba5b2"
    />

    <path
      d="M429.458773 843.933013c14.72512 8.4992 26.678613 29.20448 26.678614 46.209707 0 16.9984-11.953493 23.90016-26.678614 15.40096-14.718293-8.4992-26.678613-29.20448-26.678613-46.209707 0-16.9984 11.96032-23.90016 26.678613-15.40096z"
      fill="#0070b9"
    />
  </svg>
);

export const QrCode = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />

    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

    <g id="SVGRepo_iconCarrier">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7.505a1.5 1.5 0 0 1-1.5 1.5h-3a1.5 1.5 0 0 1-1.5-1.5V4.5A1.5 1.5 0 0 1 16.5 3h3A1.5 1.5 0 0 1 21 4.5v3.005zM9 7.5V4.504a1.5 1.5 0 0 0-1.498-1.5l-3-.003A1.5 1.5 0 0 0 3 4.5V7.5A1.5 1.5 0 0 0 4.5 9h3A1.5 1.5 0 0 0 9 7.5zM5 7V5.001l2 .002V7H5zm-.502 8.004 3-.003A1.5 1.5 0 0 1 9 16.5V19.5A1.5 1.5 0 0 1 7.5 21h-3A1.5 1.5 0 0 1 3 19.5v-2.996a1.5 1.5 0 0 1 1.498-1.5zM7 19H5v-1.997l2-.002V19zM19 5v2.005h-2V5h2zm0 11a1 1 0 1 1 2 0v3.5a1.5 1.5 0 0 1-1.498 1.5l-3.5.005a1 1 0 1 1-.003-2L19 19V16zM11 4v7H4a1 1 0 1 0 0 2h8a1 1 0 0 0 1-1V4a1 1 0 1 0-2 0zm2 16a1 1 0 1 1-2 0v-4a1 1 0 1 1 2 0v4zm3-3a1 1 0 0 0 1-1v-3h3a1 1 0 1 0 0-2h-4a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const ArrowRight = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12H20M20 12L16 8M20 12L16 16"
      className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LinkIcon = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16"
      className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Loading = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3935 5.37371C18.0253 6.70569 19.8979 10.7522 18.5761 14.4118C17.6363 17.0135 15.335 18.7193 12.778 19.0094M12.778 19.0094L13.8253 17.2553M12.778 19.0094L14.4889 20M9.60651 18.6263C5.97465 17.2943 4.10205 13.2478 5.42394 9.58823C6.36371 6.98651 8.66504 5.28075 11.222 4.99059M11.222 4.99059L10.1747 6.74471M11.222 4.99059L9.51114 4"
      className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Write = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.186 2.09c.521.25 1.136.612 1.625 1.101.49.49.852 1.104 1.1 1.625.313.654.11 1.408-.401 1.92l-7.214 7.213c-.31.31-.688.541-1.105.675l-4.222 1.353a.75.75 0 0 1-.943-.944l1.353-4.221a2.75 2.75 0 0 1 .674-1.105l7.214-7.214c.512-.512 1.266-.714 1.92-.402zm.211 2.516a3.608 3.608 0 0 0-.828-.586l-6.994 6.994a1.002 1.002 0 0 0-.178.241L9.9 14.102l2.846-1.496c.09-.047.171-.107.242-.178l6.994-6.994a3.61 3.61 0 0 0-.586-.828zM4.999 5.5A.5.5 0 0 1 5.47 5l5.53.005a1 1 0 0 0 0-2L5.5 3A2.5 2.5 0 0 0 3 5.5v12.577c0 .76.082 1.185.319 1.627.224.419.558.754.977.978.442.236.866.318 1.627.318h12.154c.76 0 1.185-.082 1.627-.318.42-.224.754-.559.978-.978.236-.442.318-.866.318-1.627V13a1 1 0 1 0-2 0v5.077c0 .459-.021.571-.082.684a.364.364 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.57-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684V5.5z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </svg>
  </motion.div>
);

export const Typification = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.7"
        d="M13.5798 13.7899C13.0765 13.9269 12.5468 14 12 14C9.46679 14 7.30024 12.4302 6.42018 10.2102C3.87293 10.9036 2 13.2331 2 16C2 19.3138 4.68629 22 8 22C11.3137 22 14 19.3138 14 16C14 15.2195 13.851 14.4739 13.5798 13.7899Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.4"
        d="M13.5798 13.7896C13.851 14.4736 14 15.2193 14 15.9998C14 17.7768 13.2275 19.3734 12 20.472C13.0615 21.4221 14.4633 21.9998 16 21.9998C19.3137 21.9998 22 19.3135 22 15.9998C22 13.2329 20.1271 10.9034 17.5798 10.21C16.8897 11.9508 15.4085 13.2918 13.5798 13.7896Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </svg>
  </motion.div>
);

export const Proposal = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 9.3345 19.8412 6.93964 18 5.29168M8 16L16 8M17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </motion.div>
);

export const Bell = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="fill-[#4d5666] dark:fill-[#fcd53f]"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M494.382,302.749c-8.063-12.985-22.605-22.83-38.333-26.087l-78.643-54.472 c-2.533-33.106-27.337-39.278-32.727-40.229l-23.067-65.128c-21.733-61.413-82.3-104.301-147.289-104.301 c-7.585,0-15.235,0.574-22.754,1.716c-0.965-1.564-2.087-3.051-3.342-4.433c-3.954-4.317-9.038-7.376-14.701-8.851 C131.064,0.326,128.523,0,125.973,0c-3.436,0-6.819,0.582-10.05,1.735c-3.801,1.343-7.252,3.412-10.268,6.153 c-4.307,3.944-7.362,9.022-8.836,14.689c-0.962,3.727-1.199,7.547-0.702,11.378C33.151,71.068,4.218,151.052,28.801,220.505 l23.051,65.124c-3.591,4.134-18.979,24.538-0.12,51.868l-26.617,91.362c-19.556,27.978-18.653,57.283,2.246,72.931 c5.77,4.309,11.252,6.805,17.166,7.838C51.363,511.2,59.979,512,70.133,512c0.006,0,0.002,0,0.008,0 c48.626,0,127.147-17.527,210.037-46.879c101.099-35.784,185.95-81.479,211.083-113.63c4-4.55,6.693-9.938,8.473-16.949 C502.458,323.77,500.607,312.776,494.382,302.749z M120.73,22.244c0.644-0.423,1.345-0.769,2.1-1.04 c0.73-0.264,1.483-0.431,2.244-0.505C124.403,20.924,121.501,21.956,120.73,22.244z M243.749,362.233 c65.501-23.195,128.395-37.042,168.244-37.042c21.341,0,34.579,4.192,37.275,11.805c8.102,22.896-72.322,73.129-175.616,109.693 c-65.516,23.195-128.424,37.046-168.275,37.046c-21.332,0-34.561-4.189-37.256-11.794 C60.026,449.05,140.453,398.805,243.749,362.233z"></path>
          <path d="M272.716,377.097c0.173,0.691,0.349,1.304,0.6,1.964c6.625,18.742,24.464,31.335,44.391,31.335h0.002 c5.328,0,10.602-0.908,15.677-2.694c17.75-6.32,29.992-22.5,31.184-41.203c0.495-6.126-0.361-12.539-2.473-18.544l-0.448-1.27 l-1.326,0.233c-26.809,4.685-57.257,12.317-88.055,22.073l-1.3,0.412l0.208,1.343C271.532,373.067,272.049,375.187,272.716,377.097 z" />
        </g>
      </g>
    </svg>
  </motion.div>
);

export const Base = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="64px"
      height="64px"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g className="fill-[#4d5666] dark:fill-[#ebeef2]">
          <ellipse cx="14" cy="8" rx="11" ry="6"></ellipse>
          <path d="M14,24c-4.8,0-8.8-1.4-11-3.6V24c0,3.4,4.8,6,11,6c0.9,0,1.8-0.1,2.7-0.2C15.2,28.3,14.3,26.2,14,24C14,24,14,24,14,24z"></path>
          <path d="M3,12.4V16c0,3.4,4.8,6,11,6c0,0,0,0,0.1,0c0.2-2.4,1.4-4.6,3-6.2c-1,0.1-2,0.2-3.1,0.2C9.2,16,5.2,14.6,3,12.4z"></path>
        </g>
        <path
          className="fill-[#4d5666] dark:fill-[#ebeef2]"
          d="M24,15c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S28.4,15,24,15z M27,24h-2v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2h-2 c-0.6,0-1-0.4-1-1s0.4-1,1-1h2v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2h2c0.6,0,1,0.4,1,1S27.6,24,27,24z"
        />
      </g>
    </svg>
  </motion.div>
);

export const Fullscreen = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g id="SVGRepo_iconCarrier">
        <g id="style=stroke">
          <g id="fullscreen">
            <path
              id="vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.25 4C1.25 2.48122 2.48122 1.25 4 1.25H8C8.41421 1.25 8.75 1.58579 8.75 2C8.75 2.41421 8.41421 2.75 8 2.75H4C3.30964 2.75 2.75 3.30964 2.75 4V8C2.75 8.41421 2.41421 8.75 2 8.75C1.58579 8.75 1.25 8.41421 1.25 8V4Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
            <path
              id="vector (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 1.25C21.5188 1.25 22.75 2.48122 22.75 4L22.75 8C22.75 8.41421 22.4142 8.75 22 8.75C21.5858 8.75 21.25 8.41421 21.25 8L21.25 4C21.25 3.30964 20.6904 2.75 20 2.75L16 2.75C15.5858 2.75 15.25 2.41421 15.25 2C15.25 1.58579 15.5858 1.25 16 1.25L20 1.25Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
            <path
              id="vector (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.75 20C22.75 21.5188 21.5188 22.75 20 22.75L16 22.75C15.5858 22.75 15.25 22.4142 15.25 22C15.25 21.5858 15.5858 21.25 16 21.25L20 21.25C20.6904 21.25 21.25 20.6904 21.25 20L21.25 16C21.25 15.5858 21.5858 15.25 22 15.25C22.4142 15.25 22.75 15.5858 22.75 16L22.75 20Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
            <path
              id="vector (Stroke)_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.25 20C1.25 21.5188 2.48122 22.75 4 22.75L8 22.75C8.41421 22.75 8.75 22.4142 8.75 22C8.75 21.5858 8.41421 21.25 8 21.25L4 21.25C3.30964 21.25 2.75 20.6904 2.75 20L2.75 16C2.75 15.5858 2.41421 15.25 2 15.25C1.58579 15.25 1.25 15.5858 1.25 16L1.25 20Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
          </g>
        </g>
      </g>
    </svg>
  </motion.div>
);

export const Logo = (props: IconProps) => (
  <svg
    width="280"
    height="19"
    viewBox="0 0 280 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.6987 10.8466H34.8799L34.0781 14.737H21.9278L22.6987 10.8466ZM29.144 6.02361L21.0643 18.4408H15.6367L27.0778 1.01409H34.294L38.7656 18.4408H33.338L30.1925 6.02361V4.53141H29.7607L29.144 6.02361Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M39.0555 1.01409H58.0828L57.281 4.90446H50.1882L47.4436 18.4408H42.5711L45.3157 4.90446H38.2537L39.0555 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M76.4482 1.01409L72.9018 18.4408H68.0602L70.8048 4.90446H65.4697L64.1128 11.6726C63.8661 12.7918 63.5064 13.7688 63.0335 14.6037C62.5606 15.4209 62.0261 16.0782 61.4299 16.5756C60.8542 17.0552 60.1655 17.4371 59.3637 17.7214C58.5619 18.0056 57.7704 18.201 56.9892 18.3076C56.2285 18.3964 55.3753 18.4408 54.4296 18.4408H53.3811L54.1829 14.4972H55.2314C55.7659 14.4972 56.2079 14.4705 56.5574 14.4172C56.9275 14.3462 57.3078 14.2129 57.6984 14.0175C58.1096 13.8221 58.4488 13.5112 58.7161 13.0849C58.9834 12.6408 59.189 12.0723 59.3329 11.3795L61.4299 1.01409H76.4482Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M81.7857 10.8466H93.9669L93.1651 14.737H81.0147L81.7857 10.8466ZM88.2309 6.02361L80.1512 18.4408H74.7237L86.1647 1.01409H93.3809L97.8525 18.4408H92.425L89.2794 6.02361V4.53141H88.8477L88.2309 6.02361Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M103.255 1.01409H108.096L106.77 7.51581H116.238L117.564 1.01409H122.436L118.859 18.4408H113.987L115.436 11.4328H105.969L104.519 18.4408H99.6775L103.255 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M124.688 1.01409H143.715L142.913 4.90446H135.82L133.076 18.4408H128.203L130.948 4.90446H123.886L124.688 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M151.38 1.01409H155.081L153.477 8.90142L163.438 1.01409H168.095L158.504 8.95471L164.518 18.4408H159.491L155.081 11.1397L152.676 12.8984L151.535 18.4408H147.803L151.38 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M173.603 5.25087C172.493 6.31672 171.742 7.8178 171.352 9.7541C170.961 11.6726 171.095 13.1648 171.753 14.2307C172.431 15.2965 173.551 15.8295 175.114 15.8295C176.697 15.8295 178.033 15.3054 179.123 14.2573C180.233 13.1915 180.984 11.6904 181.374 9.7541C181.765 7.8178 181.631 6.31672 180.973 5.25087C180.315 4.16725 179.195 3.62544 177.612 3.62544C176.049 3.62544 174.713 4.16725 173.603 5.25087ZM167.589 9.7541C168.186 6.85853 169.44 4.59359 171.352 2.95928C173.264 1.32497 175.566 0.507812 178.259 0.507812C180.768 0.507812 182.68 1.34273 183.995 3.01257C185.332 4.66465 185.712 6.91182 185.136 9.7541C184.54 12.6674 183.286 14.9413 181.374 16.5756C179.483 18.1921 177.19 19.0004 174.497 19.0004C171.804 19.0004 169.83 18.1921 168.576 16.5756C167.322 14.9413 166.993 12.6674 167.589 9.7541Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M189.025 1.01409H192.726L191.307 8.04874H199.603L201.052 1.01409H204.783L201.237 18.4408H197.475L198.986 11.0864H190.69L189.179 18.4408H185.448L189.025 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M222.846 6.60983L219.084 7.2227C218.961 6.06802 218.57 5.17981 217.912 4.55806C217.254 3.93631 216.35 3.62544 215.198 3.62544C213.595 3.62544 212.228 4.16725 211.097 5.25087C209.987 6.31672 209.226 7.83556 208.815 9.8074C208.424 11.7259 208.568 13.2092 209.247 14.2573C209.946 15.3054 211.107 15.8295 212.731 15.8295C213.924 15.8295 215.003 15.4831 215.969 14.7903C216.956 14.0975 217.696 13.1293 218.19 11.8858L221.705 12.5253C220.842 14.586 219.547 16.1848 217.82 17.3217C216.093 18.4408 214.181 19.0004 212.084 19.0004C209.37 19.0004 207.376 18.1921 206.101 16.5756C204.826 14.959 204.487 12.703 205.083 9.8074C205.68 6.85853 206.944 4.57583 208.877 2.95928C210.809 1.32497 213.132 0.507812 215.846 0.507812C217.758 0.507812 219.331 1.04074 220.564 2.10659C221.818 3.15469 222.579 4.65576 222.846 6.60983Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M228.192 11.1131H237.783L237.166 14.1241H227.575L228.192 11.1131ZM233.558 5.27751L225.818 18.4408H221.778L232.14 1.01409H237.814L241.114 18.4408H237.105L234.637 5.27751L234.607 3.78532H234.206L233.558 5.27751Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M262.153 1.01409L258.607 18.4408H254.875L257.836 4.02513H252.84L251.298 11.6726C251.072 12.7918 250.743 13.7688 250.311 14.6037C249.9 15.4209 249.437 16.0782 248.923 16.5756C248.43 17.0552 247.844 17.4371 247.166 17.7214C246.487 18.0056 245.829 18.201 245.192 18.3076C244.555 18.3964 243.845 18.4408 243.064 18.4408H242.386L243.002 15.3765H243.681C244.174 15.3765 244.585 15.341 244.914 15.2699C245.243 15.1811 245.593 15.0123 245.963 14.7636C246.353 14.5149 246.682 14.1152 246.95 13.5645C247.217 12.9961 247.443 12.2677 247.628 11.3795L249.756 1.01409H262.153Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M264.273 1.01409H280L279.383 4.02513H273.401L270.44 18.4408H266.709L269.638 4.02513H263.656L264.273 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path d="M17.8509 0H23.0657L12.4355 17.3942H7.2207L17.8509 0Z" fill="#0070B9" />
    <path d="M10.6302 0H15.845L5.21482 17.3942H0L10.6302 0Z" fill="#0070B9" />
  </svg>
);

export const LogoMobile = (props: IconProps) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9734 0.553467H9.37457L0 18.4249H4.59885L13.9734 0.553467ZM20.3411 0.553711H15.7422L6.36768 18.4251H10.9665L20.3411 0.553711Z"
      className="fill-[#0070B9]"
    />
  </svg>
);
