import { Switch } from 'antd';
import { memo, useEffect, useState } from 'react';

export const ThemeTumbler = memo(({ className }: { className?: string }) => {
  const [isDark, setIsDark] = useState(localStorage.getItem('theme') === 'dark');
  const toggleTheme = () => {
    localStorage.getItem('theme') === 'light' ? setIsDark(true) : setIsDark(false);
  };
  useEffect(() => {
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
    document.documentElement.classList.toggle('dark', isDark);
  }, [isDark]);
  return (
    <Switch
      checked={isDark}
      onChange={toggleTheme}
      checkedChildren="Dark"
      unCheckedChildren="Light"
      className={className}
    />
  );
});

export default ThemeTumbler;
