import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

const FullscreenContext = createContext(
  {} as { fullscreen: boolean; setFullscreen: React.Dispatch<React.SetStateAction<boolean>> }
);

export const FullscreenProvider = ({ children }: { children: ReactNode }) => {
  const [fullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    const value = localStorage.getItem('fullscreen');
    if (value) setFullscreen(JSON.parse(value));
  }, []);

  useEffect(() => {
    localStorage.setItem('fullscreen', JSON.stringify(fullscreen));
  }, [fullscreen]);

  return (
    <FullscreenContext.Provider value={{ fullscreen, setFullscreen }}>
      {children}
    </FullscreenContext.Provider>
  );
};

export const useFullscreen = () => {
  return useContext(FullscreenContext);
};
