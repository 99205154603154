import { EnumWhatsappStatus } from '__generated__/types';
import { useConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';

const PassedNovoregCount = () => {
  const { data } = useConnectionSessionWAList({
    variables: { filter: { status: EnumWhatsappStatus.Novoreg } },
    pollInterval: 2000,
  });

  const passedNovoregCount = data?.connectionSessionWAList?.filter((acc) => {
    const novoreg_date = new Date(acc?.waClient?.novoreg_date ?? 0);
    const now = new Date();
    const diffInMs = Math.abs(now.getTime() - novoreg_date.getTime());
    const diffInHours = diffInMs / (1000 * 60 * 60);
    const passedNovoreg = diffInHours > 36;
    if (passedNovoreg && acc?.statusWbot !== 'connected') {
      return acc;
    }
  });

  return passedNovoregCount?.length;
};

export default PassedNovoregCount;
