import { SignIn, useUser } from '@clerk/clerk-react';
import Layout from 'app/layout';
import { AppRouter } from 'app/providers/router/appRouter';
import { motion } from 'framer-motion';
import { Loading } from 'shared/assets/icons/icons';
import { FilterProvider } from 'utils/filterContext';
import { UserProvider } from './providers/userProvider/userProvider';
import { FullscreenProvider } from './providers/fullscreenProvider/fullscreenProvider';

const App = () => {
  const { user, isLoaded } = useUser();
  if (!isLoaded)
    return (
      <div className="flex h-screen items-center justify-center bg-surfaceDark">
        <motion.span
          animate={{ rotate: 360 }}
          transition={{ duration: 1.4, repeat: Infinity, repeatType: 'loop', ease: 'linear' }}
        >
          <Loading className="size-32" />
        </motion.span>
      </div>
    );

  if (!user) {
    return (
      <div className="flex h-screen items-center justify-center">
        <SignIn />
      </div>
    );
  }
  return (
    <UserProvider>
      <FilterProvider>
        <FullscreenProvider>
          <Layout>
            <AppRouter />
          </Layout>
        </FullscreenProvider>
      </FilterProvider>
    </UserProvider>
  );
};

export default App;
